import React from "react";
import darkModeIcon from "./darkBT.gif"; // darkBT.gif 이미지 파일을 가져옵니다.
import Insta2 from "./logo/Instagram.webp";
import Insta from "./logo/instaa.png";
import twitch from "./logo/twitch.png";
import naver from "./logo/naver.png";
import ytb_2 from "./logo/ytb3.png";
import tt from "./logo/tt.png";
import wvs from "./logo/wvs.svg";
import twt from "./logo/x.png";
import qwer from "./logo/qwers.jpeg";
import q from "./logo/q.jpeg";
import w from "./logo/w.jpeg";
import e from "./logo/e.jpeg";
import r from "./logo/r.jpg";
import chz from "./logo/chz.png";

import { useTranslation } from "react-i18next";
import i18n from "../src/lang/i18n";
const SnsPage = ({ darkMode, toggleDarkMode, language, langbt }) => {
  return (
    <div className={`SnsPage ${darkMode ? "dark-mode" : ""}`}>
      <h1>{language("SNS")}</h1>
      <button className="toggle-button" onClick={toggleDarkMode}>
        <div className="bwicon"></div>
        <span>{language("switMode")}</span>
      </button>
      <div class="lang-switch">
        <span class="active" onClick={langbt.ko}>
          KR
        </span>
        /{" "}
        <span class="inactive" onClick={langbt.en}>
          EN
        </span>
        /{" "}
        <span class="inactive" onClick={langbt.jp}>
          JP
        </span>
      </div>
      <div className="sns-layout">
        <div className="circle">
          <img src={qwer} alt="profile" />
        </div>
        <div className="name">{language("official")}</div>
        <div className="square-container">
          <div className="square">
            <a
              href="https://www.youtube.com/@QWER_Band_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ytb_2} alt="profile" />
            </a>
          </div>

          <div className="square">
            <a
              href="https://www.instagram.com/qwerband_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://cafe.naver.com/eggkim"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://weverse.io/qwer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={wvs} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://twitter.com/official_QWER"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twt} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.tiktok.com/@qwerband_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tt} alt="profile" />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="sns-layout">
        <div className="circle">
          <img src={q} alt="profile" />
        </div>
        <div className="name">{language("Q")}</div>
        <div className="square-container">
          <div className="square">
            <a
              href="https://www.youtube.com/@chodan_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ytb_2} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.twitch.tv/chodan_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitch} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.instagram.com/choda._.n"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://cafe.naver.com/chodancafe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.tiktok.com/@chodan__"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tt} alt="profile" />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="sns-layout">
        <div className="circle">
          <img src={w} alt="profile" />
        </div>
        <div className="name">{language("W")}</div>
        <div className="square-container">
          <div className="square">
            <a
              href="https://www.youtube.com/@마젠타"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ytb_2} alt="profile" />
            </a>
          </div>
          <div className="circle2">
            <a
              href="https://www.youtube.com/@Aheesoxnote/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://yt3.googleusercontent.com/T8-TihQZP4xw1Tl2SLL8GjQKvoCgik7lOeNw_aL6oTqsHA-3HfMk22I2JGSfb5F7tL8oszTntg=s160-c-k-c0x00ffffff-no-rj"
                alt="profile"
              />
            </a>
          </div>
          <div className="overText">
            <p className="textt">{language("Wnode")}</p>
          </div>
          <div className="overyt">
            <img className="texttt" src={ytb_2} alt="profile" />
          </div>
          <div className="square">
            <a
              href="https://www.twitch.tv/magenta62"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitch} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://chzzk.naver.com/c2bb58fbc38e1ce5da52bc1cb9a62cc5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={chz} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.instagram.com/magenta_6262"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://cafe.naver.com/magentacafe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.tiktok.com/@magenta6262"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tt} alt="profile" />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="sns-layout">
        <div className="circle">
          <img src={e} alt="profile" />
        </div>
        <div className="name">{language("E")}</div>
        <div className="square-container">
          <div className="square">
            <a
              href="https://www.youtube.com/@hapycb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ytb_2} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.instagram.com/i_am_young22"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://cafe.naver.com/nyangworld"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={naver} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.tiktok.com/@i_am_young22"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tt} alt="profile" />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className="sns-layout">
        <div className="circle">
          <img src={r} alt="profile" />
        </div>
        <div className="name">{language("R")}</div>
        <div className="square-container">
          <div className="square">
            <a
              href="https://www.instagram.com/siyo.co.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://www.tiktok.com/@siyoming___qwer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tt} alt="profile" />
            </a>
          </div>
          <div className="square">
            <a
              href="https://twitter.com/siyo_min"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twt} alt="profile" />
            </a>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <br></br>
        <br></br>
      </div>
      <footer className="footer">{language("footer")}</footer>
    </div>
  );
};

export default SnsPage;
